import { Stack, Link, VStack, Text, Circle } from "@chakra-ui/react";
import HOME_BRIDGE from "../assets/home-bridge.png";
import { ChainName } from "@omnity/widget/src/types";
import uniqBy from "lodash.uniqby";
import { CHAIN_LIST } from "@omnity/widget/src/utils/constants";

export default function ChainGroup() {
  const px = { base: 4, md: 16 };
  return (
    <Stack
      flexDirection="column"
      px={px}
      py={24}
      gap={20}
      justifyContent="center"
      alignItems="center"
      bgImage={`url(${HOME_BRIDGE})`}
      bgRepeat="no-repeat"
      bgSize="contain"
      bgPos="center"
    >
      <Text fontSize={{ base: 32, md: 48 }} lineHeight="60px" fontWeight={600}>
        Connected Chains and Listed Tokens
      </Text>

      <VStack gap={12}>
        <Text fontSize={{ base: 24, md: 32 }}>
          Chains Connected via Omnity Hub
        </Text>
        <Stack
          flexDir="row"
          flexWrap="wrap"
          justifyContent="center"
          gap={6}
          w={{ base: "", md: 1000 }}
        >
          {uniqBy(Object.values(CHAIN_LIST), (t: any) => t.chainName).map(
            (c: any) => {
              const chain = c.chainName;
              let link = `https://bridge.omnity.network/runes?targetChain=${chain}`;
              if (chain === ChainName.Bitcoin) {
                link = "https://bridge.omnity.network/runes";
              } else if (
                chain === ChainName.Osmosis ||
                chain === ChainName.Ton ||
                chain === ChainName.Core
              ) {
                link = `https://bridge.omnity.network/ckbtc?targetChain=${chain}`;
              } else if (chain === ChainName.ICP) {
                link = "https://bridge.omnity.network/icp";
              }
              const Icon = c.icon;
              return (
                <Link key={chain} href={link} target="_blank">
                  <Circle
                    size="100px"
                    overflow="hidden"
                    opacity={0.9}
                    p={0}
                    bg="black"
                    transition="transform 0.2s"
                    transitionTimingFunction="ease-in-out"
                    boxShadow="0 0 10px white"
                    _hover={{
                      opacity: 1,
                      transform: "scale(1.05)",
                      boxShadow: "0 0 10px white",
                    }}
                  >
                    <Icon size={100} />
                  </Circle>
                </Link>
              );
            },
          )}
        </Stack>
      </VStack>
    </Stack>
  );
}
