import {
  HStack,
  Image,
  Stack,
  Link,
  VStack,
  Text,
  Box,
} from "@chakra-ui/react";
import WHITE_LOGO from "../assets/white-logo.svg";
import TELEGRAM from "../assets/telegram.svg";
import TWITTER from "../assets/twitter.svg";
import MEDIUM from "../assets/medium.svg";
import YOUTEBE from "../assets/youtube.svg";
import DISCORD from "../assets/discord.svg";
import GITHUB from "../assets/github.svg";
import EMAIL from "../assets/email.svg";
import OC from "../assets/oc.png";

import { LINK_HOVER_COLOR, LITEPAPER } from "../utils/constants";

const Products = [
  {
    title: "Omnity Hub",
    href: "https://bridge.omnity.network",
  },
  {
    title: "REE",
    href: "/ree",
  },
];

const Team = [
  {
    title: "Blog",
    href: "https://medium.com/omnity",
  },
  {
    title: "Litepaper",
    href: LITEPAPER,
  },
  {
    title: "Contact",
    href: "mailto:hi@oct.network",
  },
];

const Resources = [
  {
    title: "Brand Assets",
    href: "/brand",
  },
  {
    title: "Documentation",
    href: "https://docs.omnity.network",
  },
];

const Socials = [
  {
    title: "X(Twitter)",
    icon: TWITTER,
    href: "https://twitter.com/OmnityNetwork",
  },
  {
    title: "Telegram",
    icon: TELEGRAM,
    href: "https://t.me/omnityofficial",
  },
  {
    title: "Medium",
    icon: MEDIUM,
    href: "https://medium.com/omnity",
  },
  {
    title: "Youtube",
    icon: YOUTEBE,
    href: "https://www.youtube.com/channel/UCkMYDmXdgjCBTBggSEAy0ZQ",
  },
  {
    title: "Github",
    icon: GITHUB,
    href: "https://github.com/octopus-network",
  },
  {
    title: "OC",
    icon: OC,
    href: "https://oc.app/community/o5uz6-dqaaa-aaaar-bhnia-cai/channel/55564096078728941684293384519740574712/?ref=g6b5s-jqaaa-aaaar-bfbjq-cai",
  },
  {
    title: "Discord",
    icon: DISCORD,
    href: "https://discord.gg/6GTJBkZA9Q",
  },
  {
    title: "Email",
    icon: EMAIL,
    href: "mailto:hi@oct.network",
  },
];

export default function Footer() {
  return (
    <VStack px={{ base: 4, md: 16 }} py={16}>
      <Stack
        flexDirection={{ base: "column", md: "row" }}
        w="100%"
        alignItems={{ base: "center", md: "center" }}
        justifyContent="space-between"
      >
        <VStack gap={6} alignItems={{ base: "center", md: "flex-start" }}>
          <Image src={WHITE_LOGO} alt="logo" />
          <Text
            fontSize={20}
            lineHeight={1.2}
            maxW={{ base: "100%", md: 400 }}
            textAlign={{ base: "center", md: "left" }}
          >
            Omnity: Empowering the future of Bitcoin-centric finance through
            innovative interoperability and programmability solutions.
          </Text>
        </VStack>

        <HStack gap={16} alignItems="flex-start">
          <VStack alignItems={"flex-start"}>
            <Text fontSize={20} fontWeight={700}>
              Products
            </Text>

            {Products.map((r) => {
              return (
                <Link
                  key={r.title}
                  href={r.href}
                  target={r.href.startsWith("http") ? "_blank" : "_self"}
                  _hover={{ color: LINK_HOVER_COLOR }}
                >
                  {r.title}
                </Link>
              );
            })}
          </VStack>

          <VStack alignItems={"flex-start"}>
            <Text fontSize={20} fontWeight={700}>
              Team
            </Text>

            {Team.map((r) => {
              return (
                <Link
                  key={r.title}
                  href={r.href}
                  target={r.href.startsWith("http") ? "_blank" : "_self"}
                  _hover={{ color: LINK_HOVER_COLOR }}
                >
                  {r.title}
                </Link>
              );
            })}
          </VStack>

          <VStack
            alignItems={"flex-start"}
            display={{ base: "none", md: "flex" }}
          >
            <Text fontSize={20} fontWeight={700}>
              Resources
            </Text>
            {Resources.slice(0, 3).map((s) => {
              return (
                <Link
                  key={s.title}
                  href={s.href}
                  target="_blank"
                  _hover={{ color: LINK_HOVER_COLOR }}
                >
                  {s.title}
                </Link>
              );
            })}
          </VStack>
        </HStack>
      </Stack>

      <Box w="100%" h={1} bg="rgba(29, 34, 85, 1)" my={8} />

      <Stack
        w="100%"
        alignItems={{ base: "center", md: "flex-start" }}
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="space-between"
      >
        <Text fontSize={16}>Copyright © 2025 Omnity Network</Text>

        <HStack gap={6}>
          {Socials.map((s) => {
            return (
              <Link
                key={s.title}
                href={s.href}
                target="_blank"
                color={LINK_HOVER_COLOR}
              >
                <Image src={s.icon} alt="social" w={6} h={6} key={s.title} />
              </Link>
            );
          })}
        </HStack>
      </Stack>
    </VStack>
  );
}
