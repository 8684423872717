import { getICPSwapIndexService } from "../candids";
import { TokenMeta } from "../types";

async function fetchRunePrice(token_id?: string) {
  try {
    if (!token_id) {
      return "0";
    }
    const res = await fetch(
      `https://api.omnity.network/api/price/rune/${token_id.split("-")[2]}`,
    ).then((res) => res.json());
    return String(res.price_in_usd ?? 0);
  } catch (error) {
    return "0";
  }
}

export async function fetchTokenPrice(tokens: TokenMeta[]) {
  try {
    let fetchedRunesTokens = tokens.filter((token) =>
      token.token_id.startsWith("Bitcoin-runes-"),
    );
    try {
      fetchedRunesTokens = await Promise.all(
        fetchedRunesTokens.map((rune) => fetchRunePrice(rune.metadata.rune_id)),
      ).then((results) => {
        return fetchedRunesTokens.map((rune, index) => ({
          ...rune,
          price: results[index],
        }));
      });
    } catch (error) {}

    let fetchedIcpTokens = tokens
      .filter((token) => token.token_id.startsWith("sICP-"))
      .map((t) => {
        if (t.token_id === "sICP-native-ICP") {
          return {
            ...t,
            metadata: { ledger_id: "ryjl3-tyaaa-aaaaa-aaaba-cai" },
          };
        }
        return t;
      });
    try {
      const nodeIndex = await getICPSwapIndexService();
      const allICPTokens = await nodeIndex.getAllTokens();

      fetchedIcpTokens = fetchedIcpTokens.map((icpToken) => {
        const t = allICPTokens.find(
          (t: any) => t.address === icpToken.metadata.ledger_id,
        );
        if (t) {
          return { ...icpToken, price: `${t.priceUSD}` };
        }
        return icpToken;
      });
    } catch (error) {}

    let fetchedBrc20Tokens = tokens.filter((token) =>
      token.token_id.startsWith("Bitcoinbrc20-brc20"),
    );

    return [...fetchedRunesTokens, ...fetchedIcpTokens, ...fetchedBrc20Tokens];
  } catch (error) {
    return tokens;
  }
}
