import {
  Stack,
  Link,
  VStack,
  Text,
  Grid,
  GridItem,
  Skeleton,
  HStack,
} from "@chakra-ui/react";
import { formatUnits, readableNumber } from "@omnity/widget/src/utils/format";
import { ArrowUpRight } from "lucide-react";
import { useMemo } from "react";
import { useMetadata } from "src/contexts/metadata";
import useTotalVolume from "src/hooks/useTotalVolume";

export default function Statistics() {
  const px = { base: 4, md: 16 };

  const { ticket_count, daily_ticket_count, isLoading, priceFetched, tokens } =
    useMetadata();
  const totalVolume = useTotalVolume();

  const tvl = useMemo(() => {
    const tokenVolumes = tokens.map((token) => {
      return {
        ...token,
        volume:
          Number(formatUnits(token.volumeAmount ?? 0n, token.decimals)) *
          Number(token.price ?? 0),
      };
    });
    return tokenVolumes.reduce((acc, rune) => {
      return acc + rune.volume;
    }, 0);
  }, [priceFetched, tokens.length]);
  const statsItems = [
    {
      title: "Total Volume",
      value:
        priceFetched && !isLoading ? `$${readableNumber(totalVolume, 0)}` : "",
    },
    {
      title: "TVL",
      value: priceFetched ? `$${readableNumber(tvl, 0)}` : "",
    },
    {
      title: "24H/Total Tickets",
      value: isLoading
        ? ""
        : `${daily_ticket_count}/${readableNumber(ticket_count, 0)}`,
    },
  ];

  return (
    <Stack
      id="analysis"
      flexDirection="column"
      px={px}
      py={24}
      gap={12}
      justifyContent="center"
      alignItems="center"
      pos="relative"
    >
      <VStack zIndex={10}>
        <Text
          fontSize={{ base: 32, md: 48 }}
          lineHeight="60px"
          fontWeight={600}
        >
          Omnity Hub Statistics
        </Text>
        <HStack>
          <Link
            href="https://explorer.omnity.network"
            fontSize={18}
            fontWeight={400}
          >
            More details on Omnity Explorer
          </Link>
          <ArrowUpRight size={24} strokeWidth={1} />
        </HStack>
      </VStack>

      <Grid
        w="100%"
        templateColumns={{
          base: `repeat(1, 1fr)`,
          md: `repeat(3, 1fr)`,
        }}
        pos="relative"
        gap={6}
        zIndex={10}
      >
        {statsItems.map((item) => {
          return (
            <GridItem
              key={item.title}
              w="100%"
              bg="linear-gradient(90deg, #354D9D 0%, #411DA1 100%)"
              p={8}
              borderRadius={12}
              role="group"
              _hover={{
                bg: "linear-gradient(91.94deg, #724AC5 -29.93%, #E43996 103.84%)",
              }}
            >
              <VStack gap={0}>
                <Text fontWeight={600} fontSize={20} color="gray.400">
                  {item.title}
                </Text>

                {item.value !== "" ? (
                  <Text fontSize={40} fontWeight={600}>
                    {item.value}
                  </Text>
                ) : (
                  <Skeleton h={41} w={180} mt={4} />
                )}
              </VStack>
            </GridItem>
          );
        })}
      </Grid>
    </Stack>
  );
}
